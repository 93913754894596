import { ReactComponent as TowerConnInternet4g } from './tower_connectivity_internet_4g.svg';
import { ReactComponent as TowerConnInternet5g } from './tower_connectivity_internet_5g.svg';
import { ReactComponent as TowerConnLiveview } from './tower_connectivity_liveview.svg';
import { ReactComponent as TowerConnSelfmgt } from './tower_connectivity_selfmgt.svg';
import { ReactComponent as TowerPowerconn230 } from './tower_powerconnection_230.svg';
import { ReactComponent as TowerPowerconnAutarkic } from './tower_powerconnection_autarkic.svg';
import { ReactComponent as TowerPowerconnEmergency } from './tower_powerconnection_emergency.svg';
import { ReactComponent as ProtectionOperationcenter } from './protection_operationcenter.svg';
import { ReactComponent as ProtectionRange } from './protection_range.svg';
import { ReactComponent as ProtectionSpeaker } from './protection_speaker.svg';
import { ReactComponent as TowerCameraBispectral } from './tower_camera_bispectral.svg';
import { ReactComponent as TowerCameraOptical } from './tower_camera_optical.svg';
import { ReactComponent as TowerCameraThermal } from './tower_camera_thermal.svg';
import { ReactComponent as TowerSabotageCamera } from './tower_sabotage_camera.svg';
import { ReactComponent as TowerSabotageProtection } from './tower_sabotage_protection.svg';
import { ReactComponent as DimensionsSizeOperation } from './dimensions_size_operation.svg';
import { ReactComponent as DimensionsSizeTransport } from './dimensions_size_transport.svg';
import { ReactComponent as DimensionsWeight } from './dimensions_weight.svg';

const assets = {
  tower_connectivity_internet_4g: TowerConnInternet4g,
  tower_connectivity_internet_5g: TowerConnInternet5g,
  tower_connectivity_liveview: TowerConnLiveview,
  tower_connectivity_selfmgt: TowerConnSelfmgt,
  tower_powerconnection_230: TowerPowerconn230,
  tower_powerconnection_autarkic: TowerPowerconnAutarkic,
  tower_powerconnection_emergency: TowerPowerconnEmergency,
  protection_operationcenter: ProtectionOperationcenter,
  protection_range: ProtectionRange,
  protection_speaker: ProtectionSpeaker,
  tower_camera_bispectral_amount: TowerCameraBispectral,
  tower_camera_optical_amount: TowerCameraOptical,
  tower_camera_thermal_amount: TowerCameraThermal,
  tower_sabotage_camera: TowerSabotageCamera,
  tower_sabotage_protection: TowerSabotageProtection,
  dimensions_size_operation: DimensionsSizeOperation,
  dimensions_size_transport: DimensionsSizeTransport,
  dimensions_weight: DimensionsWeight,
};

export default assets;
