import React from 'react';

// Utils
import { SCHEMA_TYPE_LONG, SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_TEXT } from '../../util/types';
import {
  isFieldForCategory,
  pickCategoryFields,
  pickCustomFieldProps,
} from '../../util/fieldHelpers.js';

import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import css from './ListingPage.module.css';
import { useIntl } from 'react-intl';

/**
 * Renders custom listing fields.
 * - SectionDetailsMaybe is used if schemaType is 'enum', 'long', or 'boolean'
 * - SectionMultiEnumMaybe is used if schemaType is 'multi-enum'
 * - SectionTextMaybe is used if schemaType is 'text'
 *
 * @param {*} props include publicData, metadata, listingFieldConfigs, categoryConfiguration
 * @returns React.Fragment containing aforementioned components
 */
const CustomListingFields = props => {
  const intl = useIntl();
  const { publicData, metadata, listingFieldConfigs, categoryConfiguration } = props;

  const { key: categoryPrefix, categories: listingCategoriesConfig } = categoryConfiguration;
  const categoriesObj = pickCategoryFields(publicData, categoryPrefix, 1, listingCategoriesConfig);
  const currentCategories = Object.values(categoriesObj);

  const isFieldForSelectedCategories = fieldConfig => {
    const isTargetCategory = isFieldForCategory(currentCategories, fieldConfig);
    return isTargetCategory;
  };
  let propsForCustomFields =
    pickCustomFieldProps(
      publicData,
      metadata,
      listingFieldConfigs,
      'listingType',
      isFieldForSelectedCategories
    ) || [];

  // propsForCustomFields

  propsForCustomFields = propsForCustomFields?.reduce((currFields, field) => {
    if (
      field.key === 'dimensions_weight' ||
      field.key === 'dimensions_size_transport' ||
      field.key === 'dimensions_size_operation'
    ) {
      const foundFieldIndex = currFields?.findIndex(
        ffield => ffield.key === 'dimensions_weight_heading'
      );
      if (foundFieldIndex !== -1) {
        currFields?.[foundFieldIndex]?.options.push({
          key: field.key,
          label: field.heading,
          text: field.text,
        });
        // currFields?.[foundFieldIndex]?.selectedOptions.push(field.key);
        currFields[foundFieldIndex].selectedOptions = [...currFields?.[foundFieldIndex]?.selectedOptions ?? [], field.key];
      } else {
        currFields.push({
          schemaType: 'multi-enum',
          showFullWidth: true,
          key: 'dimensions_weight_heading',
          heading: intl.formatMessage({ id: 'ListingPage.dimensionsWeightHeading' }),
          options: [
            {
              key: field.key,
              label: field.heading,
              text: field.text,
            },
          ],
          selectedOptions: [field.key],
        });
      }
    } else if (
      field.key === 'tower_camera_optical_amount' ||
      field.key === 'tower_camera_thermal_amount' ||
      field.key === 'tower_camera_bispectral_amount'
    ) {
      const foundFieldIndex = currFields?.findIndex(ffield => ffield.key === 'tower_camera');
      if (foundFieldIndex !== -1) {
        currFields?.[foundFieldIndex]?.options.push({
          key: field.key,
          label: field.heading,
          text: field.text,
          closeText: true,
        });
        currFields[foundFieldIndex].selectedOptions = [...currFields?.[foundFieldIndex]?.selectedOptions ?? [], field.key];
      } else {
        currFields.push({
          schemaType: 'multi-enum',
          key: 'tower_camera',
          heading: intl.formatMessage({ id: 'ListingPage.cameras' }),
          options: [
            {
              key: field.key,
              label: field.heading,
              text: field.text,
              closeText: true,
            },
          ],
          selectedOptions: [field.key],
        });
      }
    } else if (field.key === 'protection_range') {
      const foundFieldIndex = currFields?.findIndex(ffield => ffield.key === 'protection');
      if (foundFieldIndex !== -1) {
        currFields?.[foundFieldIndex]?.options?.push({
          key: field.key,
          label: field.heading,
          text: field.text,
          closeText: true,
        });
        currFields[foundFieldIndex].selectedOptions = [...currFields?.[foundFieldIndex]?.selectedOptions ?? [], field.key];
      }
    } else {
      currFields.push(field);
    }
    return currFields;
  }, []);

  return (
    <>
      <SectionDetailsMaybe {...props} isFieldForCategory={isFieldForSelectedCategories} />
      <div className={css.detailsInnerWrapper}>
        {propsForCustomFields.map(customFieldProps => {
          const { schemaType, key: fieldKey, ...fieldProps } = customFieldProps;
          return schemaType === SCHEMA_TYPE_MULTI_ENUM ? (
            <SectionMultiEnumMaybe {...fieldProps} />
          ) : schemaType === SCHEMA_TYPE_TEXT || schemaType === SCHEMA_TYPE_LONG ? (
            <SectionTextMaybe fieldKey={fieldKey} {...customFieldProps} />
          ) : null;
        })}
      </div>
    </>
  );
};

export default CustomListingFields;
