import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';

import icons from './assets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionTextMaybe = props => {
  const { text, heading, fieldKey, showIcon = true, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  const content = richText(text, {
    linkify: true,
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
    breakChars: '/',
  });

  const Icon = icons?.[fieldKey];

  return text ? (
    <section className={css.sectionText}>
      {showIcon && (
        <div className={css.listIconWrapper}>
          {Icon ? (
            <Icon />
          ) : (
            <FontAwesomeIcon icon={faCheck} fontSize={20} color="rgb(255, 9, 83)" />
          )}
        </div>
      )}
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <p style={{ marginLeft: showIcon ? 'auto' : '' }} className={textClass}>
        {content}
      </p>
    </section>
  ) : null;
};

export default SectionTextMaybe;
